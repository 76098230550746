$main-font: "Bakbak One";
$title-width: 10em;
$border-size: 2px;
$border-radius: 0.5em;
$icon-size: 3em;
$spacing: 1em;

$red: #f21d1d;
$green: #1df254;
$violet: #473d80;
$violet--transparent: #1a473d80;
$violet--lighter: #786bc4;
$violet--darker: #1c1832;
$gray: rgba(128, 128, 128, 0.1);
$black: rgba(0, 0, 0, 0.8);
$black--transparent: rgba(0, 0, 0, 0.3);

.pvp-page {
  padding-top: $spacing;
  min-height: 50vh;

  &__modal-wrapper {
    min-height: 20vh;
    min-width: 50vw;
  }

  &__message-wrapper {
    padding: 2em;
  }

  &__result-swiper-container {
    width: 70%;

    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }

  &__header-action {
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    text-transform: uppercase;
    border: $border-size solid $violet;
    border-radius: $border-radius;
    padding: $spacing;
  }

  &__title,
  &__header-title {
    text-transform: uppercase;
  }

  &__title {
    margin: $spacing 0;
  }

  &__header-title {
    margin-bottom: $spacing;
  }

  &__header-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: $spacing;
  }

  &__action-btn {
    text-align: center;
  }

  &__swiper {
    margin: $spacing 0;
  }

  &__match-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: $spacing;
  }

  &__match {
    border: 1px solid $violet--lighter;
    border-radius: $border-radius;
    transition: all 0.3s ease;

    &:hover {
      box-shadow: 0px 5px 15px var(--primary-color36);
    }
  }

  &__match-result {
    text-transform: uppercase;
    letter-spacing: 2px;
  }

  &__detail-container {
    padding: $spacing;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }

  &__match-icon {
    height: $icon-size;
    width: auto;
  }

  &__status-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    padding: $spacing 0;
    width: 100%;
  }

  &__player-status-container {
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    width: 70%;
  }

  &__text {
    text-align: center;
    &--green {
      color: $green;
    }

    &--violet {
      color: $violet;
    }

    &--red {
      color: $red;
    }
  }

  &__player-status {
    display: flex;
    justify-content: space-between;

    &--bordered {
      border-bottom: $border-size solid $violet--transparent;
    }

    &--spaced {
      padding: 0 $spacing;
    }

    &--green {
      color: $green;
    }

    &--violet {
      color: $violet;
    }

    &--red {
      color: $red;
    }
  }
}
