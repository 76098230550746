$border-size: 0.25em;
$border-radius: 15px;
$spacing: 2em;
$wrapper-spacing: 0.5em;

$scrollbar-radius: 12px;
$scrollbar-width: 0.5em;

$red: #fd0037;
$green: #7fbc55;
$white: #fff;
$violet: #473d80;
$violet--lighter: #786bc4;
$violet--darker: #1c1832;
$black: rgba(0, 0, 0, 0.8);
$black--transparent: rgba(0, 0, 0, 0.3);

.popup-modal {
  &__background,
  &__content,
  &__pop-container,
  &__pop-message,
  &__pop-btn,
  &__wrapper {
    border-radius: $border-radius;
  }

  &__background {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 9999;
    background-color: $black;

    &--transparent {
      background-color: $black--transparent;
    }
  }

  &__wrapper {
    position: absolute;
    display: flex;
    top: 50%;
    left: 50%;
    flex-direction: column;
    transform: translate(-50%, -50%);
    border-top: $border-size solid $violet--darker;
    max-width: 90%;
    max-height: 80%;
  }

  &__content {
    overflow: auto;
    background: $violet--darker;

    &::-webkit-scrollbar {
      width: $scrollbar-width;
    }

    &::-webkit-scrollbar-track {
      background-color: $black--transparent;
    }

    &::-webkit-scrollbar-thumb {
      box-shadow: inset 0 0 6px rgba($violet--lighter, 1);
      border-radius: $scrollbar-radius;
    }
  }

  &__wrapper {
    padding: $wrapper-spacing;
    background: transparent;
    border: 2px solid $violet--darker;
  }

  &__header-btn {
    position: absolute;
    background: transparent !important;
    right: 1em;
    top: 0.25em;
    color: $violet--lighter;
    border: 0;

    &:hover {
      color: $white;
    }

    &--violet {
      color: $violet;
    }
  }

  &__message {
    color: $white;

    &--positive {
      color: $green;
      font-weight: bolder;
      font-size: 20px;
    }

    &--negative {
      color: $red;
      font-weight: bolder;
      font-size: 20px;
    }
  }

  &__pop-container {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 9999;
    background-color: $black;
  }

  &__pop-message {
    position: relative;
    top: 50%;
    left: 50%;
    width: 50%;
    transform: translate(-50%, -50%);
    padding: $spacing;
    text-align: center;
    text-transform: uppercase;
    // background: $violet;
  }

  &__pop-btn {
    color: $white;
    border: 2px solid $white;
    background: transparent;
    margin-top: 0.25em;
    padding: 0.25em 0.5em;
    min-width: 5em;

    &--green {
      color: $green;
      border: 2px solid $green;
    }

    &--red {
      color: $red;
      border: 2px solid $red;
    }
  }

  &__title {
    text-align: center;
  }
}
