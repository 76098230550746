@use '../../scss/' as *;
.tf-work {
    background: var(--primary-color8);
    padding: 20px 20px 34px;
    margin-bottom: 30px;
    position: relative;
    @include transition3;
    .image {
        margin-bottom: 15px;
        height: 72px;
    }
    .title {
        color: var(--primary-color3);
        margin-bottom: 12px;
    }
    .content {
        font-size: 18px;
        line-height: 28px;
        color: var(--primary-color2);
    }
    &::before {
        opacity: 0;
        @include transition5;
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        top: 0px;
        left: 0px;
        border-left: 5px solid #7f21e7;   
        border-top: 5px solid #7f21e7;

    }
    &::after {
        opacity: 0;
        @include transition5;
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        bottom: 0px;
        right: 0px;
        border-right: 5px solid #7f21e7;
        border-bottom: 5px solid #7f21e7;

    }
    &.active,
    &:hover {
        cursor: pointer;
        box-shadow: 0px 5px 15px var(--primary-color36);
        &::before,&::after {
            opacity: 1;
            width: 50px;
            height: 50px;
        }
    }
    &.style-2 {
        text-align: center;
        padding: 13px 15px 27px;
        .image {
            margin-bottom: 37px;
            
        }
        .step {
            color: #7f21e7;
        }
        .title {
            margin-bottom: 0;
        }
        &.mb30 {
            padding-bottom: 34px;
            .image {
                margin-bottom: 30px;
            }
        }
    }
}
