$main-font: "Bakbak One";
$title-width: 10em;
$border-size: 2px;
$border-radius: 0.5em;
$icon-size: 3em;
$spacing: 1em;

$violet: #7f21e7;
$black: #141b22;

.gnocity-dropdown {
  padding: 0;
  &__toggle {
    position: relative;
    z-index: 2;
    font-family: $main-font;
    text-transform: uppercase;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: black;
    background-color: $violet;
    padding: 18px 36px;
    border: 2px solid transparent;
    transition: all 0.3s ease;

    &:active,
    &:hover,
    &:focus {
      color: #fff;
      background: transparent !important;
      border: $border-size solid $violet !important;
    }
  }

  &__menu {
    background-color: $black;
    max-height: 30vh;
    overflow: hidden;
    overflow-y: auto;
  }

  &__menu-item {
    color: $violet !important;

    &:active,
    &:hover,
    &:focus {
      color: #fff !important;
      background: $violet;
    }
  }

  &--spaced {
    margin-right: $spacing;
  }
}
