$violet: #473d80;
$violet--lighter: #786bc4;
$violet--darker: #1c1832;
$green--lighter: #21e786;
$border-radius: 0.5em;

.box-detail {
  min-width: 8em;
  padding: 0.5em 0.25em;
  background-color: $violet;
  border-radius: $border-radius;

  &__title,
  &__value {
    padding: 0;
    margin: 0;
    color: #f0f0f0;
  }

  &__title,
  &__slider-title {
    font-size: 0.5em;
    letter-spacing: 2px;
    width: 100%;
  }

  &__slider {
    width: 100%;
  }

  &__value {
    text-align: right;
  }

  &__slider-title {
    display: flex;
    justify-content: space-between;
  }

  &--active {
    background-color: $green--lighter;

    .box-detail__title,
    .box-detail__value {
      color: $violet--darker;
    }
  }
}
