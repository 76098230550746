$main-font: "Bakbak One";
$title-width: 10em;
$border-size: 2px;
$border-radius: 0.5em;
$icon-size: 3em;
$spacing: 1em;

$green: #21e786;
$violet: #473d80;
$violet--lighter: #786bc4;
$violet--darker: #1c1832;
$gray: rgba(128, 128, 128, 0.1);
$black: rgba(0, 0, 0, 0.8);
$black--transparent: rgba(0, 0, 0, 0.3);

.evolution-page {
  padding-top: $spacing;
  min-height: 50vh;

  &__message-wrapper {
    padding: 3em;
  }

  &__swiper {
    position: relative;
  }

  &__header-action {
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    text-transform: uppercase;
    border: $border-size solid $violet;
    border-radius: $border-radius;
    padding: $spacing;
  }

  &__title,
  &__header-title {
    text-transform: uppercase;
  }

  &__title {
    margin: $spacing 0;
  }

  &__header-title {
    margin-bottom: $spacing;
  }

  &__header-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: $spacing;
  }

  &__btn-container {
    display: inline-flex;
    justify-content: space-evenly;
    padding-left: $spacing;
    padding-right: $spacing;
    margin-left: $spacing;
  }

  &__btn {
    &--spaced {
      margin-right: $spacing;
    }

    &--green {
      background-color: $green !important;
    }
  }

  &__btn-grp-container {
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  &__action-btn {
    text-align: center;
  }

  &__swiper {
    position: relative;
    margin: $spacing 0;
  }

  &__match-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: $spacing;
  }

  &__match {
    border: 1px solid $violet--lighter;
    border-radius: $border-radius;
    transition: all 0.3s ease;

    &:hover {
      box-shadow: 0px 5px 15px var(--primary-color36);
    }
  }

  &__match-result {
    text-transform: uppercase;
    letter-spacing: 2px;
  }

  &__detail-container {
    padding: $spacing;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }

  &__match-icon {
    height: $icon-size;
    width: auto;
  }

  &__card-details {
    text-align: center;
    margin-bottom: $spacing;
  }

  &__card-header {
    margin-bottom: $spacing;
    text-align: right;
    color: $violet--lighter !important;
  }

  &__evolve-btn-container {
    display: flex;
    align-items: center;
    justify-content: space-around;
    text-align: center;
    position: relative;
  }

  &__evolve-title-container {
    display: flex;
    align-items: center;
    width: 50%;
  }
}
