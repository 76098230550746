@import "swiper/scss";
@import "swiper/scss/navigation";
@import "../../scss/media-breakpoints";

$main-font: "Bakbak One";
$spacing: 1em;
$title-width: 10em;
$slider-image-height: 10em;
$image-height: 60vh;

$violet: #473d80;
$violet--lighter: #786bc4;
$violet--darker: #1c1832;
$gray: rgba(128, 128, 128, 0.1);
$black: rgba(0, 0, 0, 0.8);
$black--transparent: rgba(0, 0, 0, 0.3);

.marketplace-page {
  min-height: 50vh;

  &__message-wrapper {
    padding: 3em;
  }

  &__title,
  &__detail-title,
  &__detail-value {
    font-family: $main-font;
  }

  &__title {
    text-transform: uppercase;
    width: 100%;

    &--between {
      display: flex;
      justify-content: space-between;
    }

    &--spaced {
      display: flex;
      justify-content: space-between;
    }
  }

  &__modal-content {
    min-width: 80vw;
    padding: $spacing 2em $spacing 2em;
  }

  &__title,
  &__skills-container {
    padding: $spacing;
  }

  &__modal-content {
    display: flex;
    justify-content: space-around;
  }

  &__btn-container {
    display: inline-flex;
    justify-content: space-around;
  }

  &__skills-container {
    display: grid;
    gap: $spacing;
    grid-template-columns: 1fr 1fr 1fr;
  }

  &__modal-image {
    height: $image-height;
    width: auto;
  }

  &__container {
    width: 100%;
  }

  &__btn {
    margin: 0 $spacing;

    &--small {
      padding: 0;
      padding: 0.25em $spacing !important;
    }
  }

  &__details-container,
  &__detail-value {
    display: flex;
  }

  &__details-container {
    padding: 0 $spacing;
  }

  &__detail-title {
    min-width: $title-width;
    color: $violet--lighter;
    padding-right: $spacing;
  }

  &__card-btn-container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }

  &__detail-value {
    position: relative;
    right: 0;
    justify-content: end;
  }

  &__detail-form {
    display: grid;
    grid-template-columns: 2fr 1fr;
  }

  &__slider-image {
  }

  &__card-name {
    width: 100%;
    text-align: center;
    color: $violet--lighter;
    margin-bottom: $spacing;
  }

  &__bg {
    &--gray {
      background-color: $gray;
    }
  }

  &__title-grid {
    display: grid;
    gap: $spacing;

    @include mobile {
      grid-template-columns: 1fr;
    }

    @include tablet {
      grid-template-columns: 1fr 1fr 1fr;
    }

    @include desktop {
      display: grid;
      grid-template-columns: 1fr 4fr 1fr;
    }
  }

  &__card-grid {
    display: grid;
    gap: $spacing;

    @include mobile {
      grid-template-columns: 1fr 1fr;
    }

    @include tablet {
      grid-template-columns: 1fr 1fr 1fr;
    }

    @include desktop {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    }
  }
}
