@import "../../scss/media-breakpoints";

$main-font: "Bakbak One";
$title-width: 10em;
$border-size: 2px;
$border-radius: 0.5em;
$icon-size: 3em;
$spacing: 1em;

$green: #21e786;
$violet: #473d80;
$violet--lighter: #786bc4;
$violet--darker: #1c1832;
$gray: rgba(128, 128, 128, 0.1);
$black: rgba(0, 0, 0, 0.8);
$black--transparent: rgba(0, 0, 0, 0.3);

.pets-page {
  padding-top: $spacing;
  min-height: 50vh;

  &__btn {
    margin: 0 $spacing;

    &--small {
      padding: 0;
      padding: 0.25em $spacing !important;
    }
  }

  &__message-wrapper {
    padding: 3em;
  }

  &__header-action {
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    text-transform: uppercase;
    border: $border-size solid $violet;
    border-radius: $border-radius;
    padding: $spacing;
  }

  &__title,
  &__header-title {
    text-transform: uppercase;
  }

  &__title {
    margin: $spacing 0;
  }

  &__header-title {
    margin-bottom: $spacing;
  }

  &__header-container {
    gap: $spacing;

    @include mobile {
      display: flex;
      flex-direction: column;
    }

    @include desktop {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
    }
  }

  &__btn-container {
    display: inline-flex;
    justify-content: space-evenly;
    padding-left: $spacing;
    padding-right: $spacing;
    margin-left: $spacing;
  }

  &__btn {
    &--spaced {
      margin-right: $spacing;
    }

    &--green {
      background-color: $green !important;
    }
  }

  &__action-btn {
    text-align: center;
  }

  &__swiper {
    margin: $spacing 0;
  }

  &__match-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: $spacing;
  }

  &__match {
    border: 1px solid $violet--lighter;
    border-radius: $border-radius;
    transition: all 0.3s ease;

    &:hover {
      box-shadow: 0px 5px 15px var(--primary-color36);
    }
  }

  &__match-result {
    text-transform: uppercase;
    letter-spacing: 2px;
  }

  &__detail-container {
    padding: $spacing;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }

  &__match-icon {
    height: $icon-size;
    width: auto;
  }

  &__title-grid {
    display: grid;
    gap: $spacing;

    @include mobile {
      grid-template-columns: 1fr;
    }

    @include tablet {
      grid-template-columns: 1fr 1fr 1fr;
    }

    @include desktop {
      display: grid;
      grid-template-columns: 1fr 4fr 1fr;
    }
  }

  &__card-grid {
    display: grid;
    gap: $spacing;

    @include mobile {
      grid-template-columns: 1fr 1fr;
    }

    @include tablet {
      grid-template-columns: 1fr 1fr 1fr;
    }

    @include desktop {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    }
  }
}
