$component-width: 20em;
$line-height: 2em;
$padding: 14px 24px;

.inputs {
  &__component {
    border: none;
    outline: 0;
    box-shadow: none;

    min-width: $component-width;
    line-height: $line-height;
    padding: $padding;
    width: 100%;
    background-color: var(--primary-color17);
    color: var(--primary-color2);
  }

  &__value-container {
    text-transform: capitalize;
  }
}
